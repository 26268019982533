<template>
  <sensor-dashboard
    :sensorId="sensorId"
    @sensor-selected="(id) => (sensorId = id)"
  />
</template>

<script>
import SensorDashboard from "@/components/views/SensorDashboard.vue";
export default {
  components: { SensorDashboard },
  data() {
    return { sensorId: null };
  },
};
</script>

<style>
</style>